import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import { Link } from "gatsby";
import * as React from "react";
import useDisplayIcon from "../../core/hooks/useDisplayIcon";
import "./guideCard.scss";
import { useEffect } from "react";

const cardLocations = {
  "purple-license": "/anims/purple_license_anim.riv",
  "green-license": "/anims/green_license_anim.riv",
  "questionmark-license": "/anims/question_license_anim.riv",
  "test-sheet": "/anims/test_sheet_anim.riv",
};

const GuideCardWithIcon = ({ title, description, iconType, slug }) => {
  const [display, iconRef, calculate] = useDisplayIcon();
  const { rive, RiveComponent } = useRive(
    {
      src: cardLocations?.[iconType] || "/anims/purple_license_anim.riv",
      stateMachines: "toggleRunState",
      autoplay: false,
      onLoad: () => {
        calculate && setTimeout(() => calculate(true), [350]);
      },
    },
    { fitCanvasToArtboardHeight: true },
  );
  const toggleState = useStateMachineInput(rive, "toggleRunState", "shouldRun");

  useEffect(() => {
    if (display) {
      if (typeof window !== "undefined") {
        if (window.innerWidth <= 1150) {
          rive && rive.play();
          if (toggleState) {
            toggleState.value = true;
            setTimeout(() => {
              //plays the animation twice
              toggleState.value = false;
            }, 2100);
          }
        }
      }
    }
  }, [display]);

  return (
    <Link to={slug}>
      <div
        ref={iconRef}
        className="guide-card"
        onMouseEnter={() => {
          rive && rive.play();
          if (toggleState) {
            toggleState.value = true;
          }
        }}
        onMouseLeave={() => {
          if (toggleState) {
            toggleState.value = false;
          }
        }}
      >
        <div className="guide-card-wrapper">
          {iconType && <RiveComponent className="rive-icon" />}
          <div className="guide-card-info">
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const GuideCard = ({ title, description, slug }) => {
  return (
    <Link to={slug}>
      <div className="guide-card no-icon-guide">
        <div className="guide-card-wrapper">
          <div className="guide-card-info">
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const GuideCardContainer = ({ children, className }) => (
  <div className={`guide-card-container ${className}`}>{children}</div>
);
export default GuideCardWithIcon;
