import * as React from "react";
import "./header.scss";
import "./headerBottomAccent.scss";
import headerAccent from "../../../../images/header/headerAccent.svg";
import useFindHeader from "../../../../core/hooks/useFindHeader";
import ButtonContainer from "../../../basics/button/buttonContainer";
import Button from "../../../basics/button/button";

/**
 * Header compontent with image
 *
 * @param {object} queryData - queryData from the graphql query
 * @param {string} title - title of the header
 * @param {string} subTitle - subTitle of the header
 * @param {object} primaryCta - primary Call to action button data
 * @param {object} secondaryCta - secondary call to action button data
 * @param {boolean} bottomAccent - bottom accent div toggle
 * @param {string} image - image data
 * @returns {React.ReactElement}
 */

const AnimatedHeader = ({ queryData, bottomAccent = true }) => {
  const headerData = useFindHeader(queryData);

  return (
    <header className="site-header-animated centered-header">
      <div className="header-content">
        <h1>{headerData.title}</h1>
        <p className="header-text">{headerData.subTitle}</p>
        <ButtonContainer>
          {headerData?.primaryCta ? (
            <Button
              size="large"
              externalLink={headerData?.primaryCta?.isExternal}
              text={headerData?.primaryCta?.text}
              url={headerData?.primaryCta?.link}
            />
          ) : null}
          {headerData?.secondaryCta ? (
            <Button
              size="large"
              type="altBlock"
              externalLink={headerData?.secondaryCta?.isExternal}
              text={headerData?.secondaryCta?.text}
              url={headerData?.secondaryCta?.link}
            />
          ) : null}
        </ButtonContainer>
      </div>

      <div className="header-backdrop">
        <div className="header-blob first-blob"></div>
        <div className="header-blob second-blob"></div>
        <div className="header-blob third-blob"></div>
        <div className="header-blob fourth-blob"></div>
      </div>

      <div className="scroll-down-icon"></div>
      <span className="scroll-down-icon-text">Scroll Down</span>
      {bottomAccent && (
        <img className="header-bottom-accent" alt="" src={headerAccent} />
      )}
    </header>
  );
};
export default AnimatedHeader;
