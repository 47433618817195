import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../../../components/layout/layout";
import PageContent from "../../../components/layout/content";
import { useMemo } from "react";
import "../../../theme/style.scss";
import Seo from "../../../components/basics/seo/seo";
import AnimatedHeader from "../../../components/layout/header/animatedHeader/animatedHeader";
import {
  FrameBlock100,
  FrameSectionRow,
  FrameSectionTitle,
} from "../../../components/basics/frame/frameBlocks";
import {
  GuideCard,
  GuideCardContainer,
} from "../../../components/guidecard/guideCard";
import GuideCardWithIcon from "../../../components/guidecard/guideCard";

const GuideIndexPage = ({ data }) => {
  const categoryData = useMemo(() => data.contentfulOldalKategoria, [data]);
  const contents = useMemo(
    () => data?.allContentfulOldal?.edges?.map((edge) => edge.node),
    [data],
  );

  return (
    <Layout>
      <Seo
        title={categoryData.cm}
        description={categoryData.alcm}
        url={
          categoryData?.slug
            ? `${categoryData?.slug}/${contents.slug}`
            : categoryData?.slug
        }
      />
      <AnimatedHeader queryData={categoryData?.header} />
      <PageContent>
        <FrameSectionTitle>
          Choose <u>your</u> guide
        </FrameSectionTitle>
        <FrameSectionRow>
          <FrameBlock100>
            <h3 className="guide-h3">Featured guides</h3>
            <GuideCardContainer>
              {contents
                .filter((content) => content?.tartalomTpus?.topicType)
                .map(({ cm, contenful_id, slug, sszest, tartalomTpus }) => (
                  <GuideCardWithIcon
                    title={cm}
                    description={sszest?.sszest}
                    key={contenful_id}
                    slug={`/${categoryData?.slug}/${slug}`}
                    iconType={tartalomTpus?.topicType}
                  />
                ))}
            </GuideCardContainer>

            {contents.filter((content) => !content?.tartalomTpus?.topicType)
              .length !== 0 && (
              <>
                <h3 className="guide-h3 other-guide">Other guides</h3>
                <GuideCardContainer className={"other-guide-container"}>
                  {contents
                    .filter((content) => !content?.tartalomTpus?.topicType)
                    .map(({ cm, contenful_id, slug, sszest }) => (
                      <GuideCard
                        title={cm}
                        description={sszest?.sszest}
                        key={contenful_id}
                        slug={`/${categoryData?.slug}/${slug}`}
                      />
                    ))}
                </GuideCardContainer>
              </>
            )}
          </FrameBlock100>
        </FrameSectionRow>
      </PageContent>
    </Layout>
  );
};
export default GuideIndexPage;
//the query gets the category name

//contenfulId is the category contentful_id
export const query = graphql`
  query general($contentfulId: String) {
    contentfulOldalKategoria(contentful_id: { eq: $contentfulId }) {
      cm: kategria
      slug
      alcm: rvidlers
      header {
        lers
        title
        primaryCta {
          ... on ContentfulCtaGomb {
            ctaGombLink {
              ... on ContentfulOldal {
                slug
                kategria {
                  slug
                }
              }
              ... on ContentfulOldalKategoria {
                slug
              }
            }
            ctaGombSzveg
            externalCtaGombLink
          }
        }
        secondaryCta {
          ... on ContentfulCtaGomb {
            ctaGombLink {
              ... on ContentfulOldal {
                slug
                kategria {
                  slug
                }
              }
              ... on ContentfulOldalKategoria {
                slug
              }
            }
            ctaGombSzveg
            externalCtaGombLink
          }
        }
      }
    }
    allContentfulOldal(
      filter: { kategria: { contentful_id: { eq: $contentfulId } } }
    ) {
      edges {
        node {
          contentful_id
          slug
          cm
          alcm
          sszest {
            sszest
          }
          tartalomTpus {
            ... on ContentfulGuidesTartalomTipus {
              header {
                cm: title
                alcm: lers
              }
              topicType
            }
            __typename
          }
        }
      }
    }
  }
`;
